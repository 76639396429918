import { css } from '#styled-system/css/css.js'
import { cx } from '#styled-system/css/cx.js'
import { truncate } from '#styled-system/patterns/truncate.js'

export const HomepageHero = () => {
	return (
		<svg
			viewBox="0 0 1270 685"
			xmlns="http://www.w3.org/2000/svg"
			className={css({
				marginTop: '64px',
				maxWidth: '1270px' as any,
				overflow: 'hidden',
				borderTopRadius: '2px',
				borderInlineWidth: '2px',
				borderTopWidth: '2px',
				borderColor: 'gray6',
				'@media(min-width:1024px)': {
					borderTopRadius: '24px',
				},
			})}
		>
			<foreignObject
				height={685}
				width={1270}
				x={0}
				y={0}
				className={css({
					outlineStyle: 'dashed',
					outlineColor: 'gray10',
				})}
			>
				<div
					className={css({
						display: 'flex',
						height: '685px' as any,
						width: '1270px' as any,
						backgroundColor: 'gray2',
						color: 'gray11',
					})}
					// @ts-ignore
					xmlns="http //www.w3.org/1999/xhtml"
				>
					<div
						className={css({
							display: 'flex',
							width: '256px',
						})}
					>
						<div
							className={css({
								display: 'flex',
								minHeight: '0px',
								width: '100%',
								flex: '1 1 0%',
								flexDirection: 'column',
								borderRightWidth: '1px',
								borderColor: 'gray6',
								backgroundColor: 'gray1',
							})}
						>
							<div
								className={css({
									marginInline: '8px',
									paddingInline: '12px',
									paddingBlock: '32px',
								})}
							>
								<span
									className={css({
										display: 'flex',
										cursor: 'pointer',
										alignItems: 'center',
										gap: '8px',
										borderRadius: '6px',
									})}
								>
									<svg
										className={css({
											height: '28px',
											width: 'auto',
										})}
										width="26"
										height="29"
										viewBox="0 0 26 29"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M12.733 2.08691L23.647 8.22601V20.5042L12.733 26.6433L1.81909 20.5042V8.22601L12.733 2.08691Z"
											fill="white"
										></path>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M10.2816 1.37886C11.8037 0.522697 13.6622 0.522697 15.1842 1.37886L22.9172 5.72864C24.4916 6.61423 25.4659 8.28015 25.4659 10.0865V18.6439C25.4659 20.4503 24.4916 22.1162 22.9172 23.0018L15.1842 27.3516C13.6622 28.2077 11.8037 28.2077 10.2816 27.3516L2.54869 23.0018C0.974309 22.1162 0 20.4503 0 18.6439V10.0865C0 8.28015 0.97431 6.61423 2.54869 5.72864L10.2816 1.37886ZM6.18668 7.8563C4.61229 8.74189 3.63798 10.4078 3.63798 12.2142V16.5162C3.63798 18.3226 4.61229 19.9885 6.18667 20.8741L10.2816 23.1775C11.8037 24.0337 13.6622 24.0337 15.1842 23.1775L19.2792 20.8741C20.8536 19.9885 21.8279 18.3226 21.8279 16.5162V12.2142C21.8279 10.4078 20.8536 8.7419 19.2792 7.85631L15.1842 5.55289C13.6622 4.69673 11.8037 4.69673 10.2816 5.55289L6.18668 7.8563Z"
											fill="white"
										></path>
										<path
											d="M12.733 2.08691L23.647 8.22601V20.5042L12.733 26.6433L1.81909 20.5042V8.22601L12.733 2.08691Z"
											fill="white"
										></path>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M10.2816 2.42232C11.8036 1.56615 13.6621 1.56616 15.1842 2.42232L22.0076 6.2605C23.582 7.1461 24.5563 8.81201 24.5563 10.6184V18.1119C24.5563 19.9183 23.582 21.5842 22.0076 22.4698L15.1842 26.308C13.6621 27.1642 11.8036 27.1642 10.2816 26.308L3.45812 22.4698C1.88373 21.5842 0.909424 19.9183 0.909424 18.1119V10.6184C0.909424 8.81202 1.88374 7.14609 3.45812 6.2605L10.2816 2.42232ZM5.27711 7.32434C3.70272 8.20993 2.72841 9.87585 2.72841 11.6822V17.0481C2.72841 18.8545 3.70273 20.5204 5.27711 21.406L10.2816 24.221C11.8036 25.0771 13.6621 25.0771 15.1842 24.221L20.1886 21.406C21.763 20.5204 22.7373 18.8545 22.7373 17.0481V11.6822C22.7373 9.87585 21.763 8.20993 20.1886 7.32434L15.1842 4.50933C13.6621 3.65317 11.8036 3.65317 10.2816 4.50933L5.27711 7.32434Z"
											fill="#111111"
										></path>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M19.0973 5.68939L9.13084 11.2955L12.7327 13.3216L23.4001 7.3212L24.2918 8.90659L13.6422 14.897V26.9923H11.8232V14.897L7.27571 12.339L7.25977 12.348L7.24501 12.3218L1.17358 8.90659L2.06536 7.3212L7.27571 10.252L18.2055 4.104L19.0973 5.68939Z"
											fill="#111111"
										></path>
										<path
											d="M20.5823 12.9775L19.4163 13.6305V17.624L19.3676 17.6513L16.9762 14.997L15.9482 15.5727V21.9227L17.1196 21.2667V17.2701L17.1602 17.2473L19.5705 19.8941L20.5823 19.3275V12.9775Z"
											fill="#111111"
										></path>
									</svg>
									<span
										className={css({
											fontSize: '20px',
											lineHeight: '28px',
											fontWeight: '900',
											color: 'gray12',
										})}
									>
										Notion Icons
									</span>
									<span
										className={css({
											borderRadius: '9999px',
											backgroundColor: 'primary3',
											paddingInline: '8px',
											paddingBlock: '4px',
											fontSize: '12px',
											fontWeight: '500',
											textTransform: 'uppercase',
											lineHeight: '1',
											letterSpacing: '0.025em',
											color: 'primary12',
										})}
									>
										beta
									</span>
								</span>
							</div>
							<div
								className={css({
									display: 'flex',
									flex: '1 1 0%',
									flexDirection: 'column',
									gap: '32px',
									overflowY: 'auto',
								})}
							>
								<div
									className={css({
										display: 'flex',
										flexDirection: 'column',
										gap: '20px',
										paddingBlock: '4px',
									})}
								>
									<span
										className={css({
											display: 'flex',
											cursor: 'pointer',
											alignItems: 'center',
											gap: '20px',
											borderRadius: '6px',
											paddingInline: '12px',
											paddingBlock: '8px',
											fontWeight: '500',
											color: 'gray12',
											'&:hover': {
												backgroundColor: 'gray4',
											},
											'@media(min-width:768px)': {
												gap: '12px',
												fontSize: '14px',
												lineHeight: '20px',
											},
										})}
									>
										<span
											className={css({
												flexShrink: '0',
												strokeWidth: '1px',
												color: 'gray11',
												'& > svg': {
													height: '24px',
													width: '24px',
												},
											})}
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												fill="none"
												viewBox="0 0 24 24"
												strokeWidth="1.5"
												stroke="currentColor"
											>
												<path
													strokeLinecap="round"
													strokeLinejoin="round"
													d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z"
												></path>
											</svg>
										</span>
										Favorites
									</span>
									<div
										className={css({
											display: 'flex',
											flexDirection: 'column',
											gap: '4px',
										})}
									>
										<h4
											className={css({
												paddingInline: '20px',
												fontSize: '14px',
												lineHeight: '20px',
												color: 'gray11',
											})}
										>
											Icon Sets
										</h4>
										<div
											className={css({
												display: 'flex',
												flexDirection: 'column',
												gap: '4px',
											})}
										>
											<div
												className={css({
													display: 'flex',
													flexDirection: 'column',
													gap: '4px',
												})}
											>
												<span
													className={css({
														marginInline: '8px',
														display: 'flex',
														cursor: 'pointer',
														alignItems: 'center',
														gap: '20px',
														borderRadius: '6px',
														paddingInline: '12px',
														paddingBlock: '8px',
														fontWeight: '500',
														color: 'gray12',
														'&:hover': {
															backgroundColor: 'gray4',
														},
														'@media(min-width:768px)': {
															gap: '12px',
															fontSize: '14px',
															lineHeight: '20px',
														},
													})}
												>
													<span
														className={css({
															flexShrink: '0',
															strokeWidth: '1px',
															color: 'gray11',
															'& > svg': {
																height: '24px',
																width: '24px',
															},
														})}
													>
														<svg
															xmlns="http://www.w3.org/2000/svg"
															fill="none"
															viewBox="0 0 24 24"
														>
															<path
																fill="currentColor"
																d="M12 2C6.483 2 2 6.483 2 12c0 5.483 4.517 10 10 10 5.517 0 10-4.483 10-10-.035-5.483-4.517-10-10-10ZM5.897 5.897A8.58 8.58 0 0 1 12 3.379c2.31 0 4.448.897 6.103 2.518C19.76 7.517 20.621 9.69 20.621 12c0 1.207-.242 2.38-.724 3.483a.766.766 0 0 1-1.345.103l-.345-.552a1.835 1.835 0 0 0-1.586-.862c-.655 0-1.242.345-1.586.862l-.276.449a.795.795 0 0 1-.69.38.795.795 0 0 1-.69-.38L9.655 9.552a1.505 1.505 0 0 0-1.276-.724c-.517 0-1 .275-1.276.724L4.517 13.69c-.241.414-.862.276-.965-.173A7.31 7.31 0 0 1 3.414 12a8.407 8.407 0 0 1 2.483-6.103Z"
															></path>
															<path
																fill="currentColor"
																d="M16.62 13.207a2.075 2.075 0 0 0 2.07-2.07 2.075 2.075 0 0 0-2.07-2.068 2.075 2.075 0 0 0-2.068 2.069c0 1.138.93 2.069 2.069 2.069Z"
															></path>
														</svg>
													</span>
													Atlas
												</span>
												<span
													className={css({
														marginInline: '8px',
														display: 'flex',
														cursor: 'pointer',
														alignItems: 'center',
														gap: '20px',
														borderRadius: '6px',
														backgroundColor: 'gray5',
														paddingInline: '12px',
														paddingBlock: '8px',
														fontWeight: '500',
														color: 'gray12',
														'&:hover': {
															backgroundColor: 'gray4',
														},
														'@media(min-width:768px)': {
															gap: '12px',
															fontSize: '14px',
															lineHeight: '20px',
														},
													})}
												>
													<span
														className={css({
															flexShrink: '0',
															strokeWidth: '1px',
															color: 'gray11',
															'& > svg': {
																height: '24px',
																width: '24px',
															},
														})}
													>
														<svg
															width="24"
															height="25"
															viewBox="0 0 24 25"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																fillRule="evenodd"
																clipRule="evenodd"
																d="M7.822 7.48c.908 0 1.766.219 2.525.605a7.65 7.65 0 0 0-1.395 2.42 2.785 2.785 0 1 0 1.655 2.547A5.571 5.571 0 1 1 12 16.737 5.571 5.571 0 1 1 7.822 7.48Zm5.571 5.572a2.786 2.786 0 1 0 5.571 0 2.786 2.786 0 0 0-5.571 0Z"
																fill="currentColor"
															></path>
														</svg>
													</span>
													Coolicons
												</span>
												<span
													className={css({
														marginInline: '8px',
														display: 'flex',
														cursor: 'pointer',
														alignItems: 'center',
														gap: '20px',
														borderRadius: '6px',
														paddingInline: '12px',
														paddingBlock: '8px',
														fontWeight: '500',
														color: 'gray12',
														'&:hover': {
															backgroundColor: 'gray4',
														},
														'@media(min-width:768px)': {
															gap: '12px',
															fontSize: '14px',
															lineHeight: '20px',
														},
													})}
												>
													<span
														className={css({
															flexShrink: '0',
															strokeWidth: '1px',
															color: 'gray11',
															'& > svg': {
																height: '24px',
																width: '24px',
															},
														})}
													>
														<svg
															width="24"
															height="25"
															viewBox="0 0 24 25"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																d="M15.25 2.988a6.198 6.198 0 0 1 3.93 1.17 6.214 6.214 0 0 1 2.405 3.608c.488 2.015-.125 4.25-1.583 5.73-1.986 2.013-3.993 4.007-5.988 6.013a.943.943 0 0 1-.717.314c-2.287.002-4.573.004-6.858-.001-.111.014-.253-.031-.338.063-.781.776-1.555 1.56-2.338 2.333-.327.348-.933.352-1.26.002-.341-.327-.336-.923.008-1.246.786-.8 1.593-1.581 2.374-2.387.04-.145.017-.3.022-.45-.003-2.225 0-4.45 0-6.676a.944.944 0 0 1 .306-.73l5.515-5.513c.387-.392.78-.785 1.235-1.102a6.19 6.19 0 0 1 3.286-1.128ZM13.191 5.42c-.54.329-.963.806-1.41 1.245-1.7 1.702-3.404 3.402-5.104 5.107.002 1.676 0 3.352.001 5.028 2.743-2.741 5.483-5.484 8.228-8.224.317-.34.902-.363 1.238-.038.37.321.38.948.018 1.28-1.561 1.567-3.126 3.13-4.691 4.692 1.674-.002 3.348 0 5.022 0 .767-.767 1.537-1.529 2.293-2.306 1.119-1.174 1.489-2.982.936-4.502a4.453 4.453 0 0 0-2.736-2.714 4.447 4.447 0 0 0-3.795.432ZM9.559 16.423c-.54.548-1.088 1.088-1.63 1.634h5.032l1.772-1.778c-1.64.001-3.28-.003-4.918.001-.113-.018-.185.076-.256.143Z"
																fill="currentColor"
															></path>
														</svg>
													</span>
													Feather
												</span>
												<span
													className={css({
														marginInline: '8px',
														display: 'flex',
														cursor: 'pointer',
														alignItems: 'center',
														gap: '20px',
														borderRadius: '6px',
														paddingInline: '12px',
														paddingBlock: '8px',
														fontWeight: '500',
														color: 'gray12',
														'&:hover': {
															backgroundColor: 'gray4',
														},
														'@media(min-width:768px)': {
															gap: '12px',
															fontSize: '14px',
															lineHeight: '20px',
														},
													})}
												>
													<span
														className={css({
															flexShrink: '0',
															strokeWidth: '1px',
															color: 'gray11',
															'& > svg': {
																height: '24px',
																width: '24px',
															},
														})}
													>
														<svg
															width="24"
															height="25"
															viewBox="0 0 24 25"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																d="M12 3.73a16.181 16.181 0 0 0 9.55 3.364c.132.754.2 1.531.2 2.325 0 6.146-4.12 11.334-9.75 12.946-5.63-1.612-9.75-6.8-9.75-12.946 0-.794.068-1.571.2-2.326A16.18 16.18 0 0 0 12 3.73v0Z"
																stroke="currentColor"
																strokeWidth="2.5"
																strokeLinecap="round"
																strokeLinejoin="round"
															></path>
														</svg>
													</span>
													Heroicons
												</span>
												<span
													className={css({
														marginInline: '8px',
														display: 'flex',
														cursor: 'pointer',
														alignItems: 'center',
														gap: '20px',
														borderRadius: '6px',
														paddingInline: '12px',
														paddingBlock: '8px',
														fontWeight: '500',
														color: 'gray12',
														'&:hover': {
															backgroundColor: 'gray4',
														},
														'@media(min-width:768px)': {
															gap: '12px',
															fontSize: '14px',
															lineHeight: '20px',
														},
													})}
												>
													<span
														className={css({
															flexShrink: '0',
															strokeWidth: '1px',
															color: 'gray11',
															'& > svg': {
																height: '24px',
																width: '24px',
															},
														})}
													>
														<svg
															width="24"
															height="24"
															viewBox="0 0 24 24"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																d="M10.707 16.221a.45.45 0 0 1 .293.409v4.97a.395.395 0 0 1-.442.397 9.82 9.82 0 0 1-5.965-3.087A9.806 9.806 0 0 1 2 12.27V9.408C2 9.183 2.183 9 2.41 9h1.707a.41.41 0 0 1 .258.091l2.917 2.365a.46.46 0 0 1 .136.49 2.361 2.361 0 0 0 .848 2.552l1.803 1.373c.195.143.405.26.628.35ZM21.59 9c.226 0 .41.182.41.408v2.861c0 2.46-.926 4.83-2.593 6.64a9.82 9.82 0 0 1-5.965 3.088A.395.395 0 0 1 13 21.6v-4.973a.45.45 0 0 1 .29-.408c.223-.093.433-.214.626-.36l1.809-1.374a2.36 2.36 0 0 0 .871-2.43.457.457 0 0 1 .143-.466l3.079-2.492a.41.41 0 0 1 .256-.091L21.59 9ZM20 5.93c0 .173-.041.343-.121.499-.08.155-.197.292-.34.4l-.346.26-2.055 1.544-1.706 1.28a.495.495 0 0 1-.472.05 2.588 2.588 0 0 0-.924-.172H9.769c-.278 0-.552.045-.812.131a.495.495 0 0 1-.452-.058L4.811 7.089l-.345-.26a1.177 1.177 0 0 1-.343-.4A1.09 1.09 0 0 1 4 5.93c0-.172.043-.343.123-.498.08-.156.198-.293.343-.401l1.027-.772c.222-.168.5-.259.786-.259h.216c.23 0 .413.174.45.384.152.857.811 1.616 1.863 2.087a7.508 7.508 0 0 0 3.094.618 7.535 7.535 0 0 0 3.099-.618c1.05-.485 1.707-1.233 1.859-2.087A.462.462 0 0 1 17.31 4h.415c.286 0 .564.091.786.259l1.027.772c.145.108.26.246.34.401.08.156.122.326.122.498ZM15 12.756a.73.73 0 0 1-.082.337.76.76 0 0 1-.229.266l-1.144.83-.682.5-.077.057c-.225.165-.5.254-.782.254-.283 0-.56-.088-.785-.254l-.082-.058L9.31 13.35A.75.75 0 0 1 9 12.748c0-.117.027-.232.081-.337a.756.756 0 0 1 .23-.266.788.788 0 0 1 .462-.145H14.2c.172 0 .339.053.475.154l.087.075a.732.732 0 0 1 .237.527Z"
																fill="currentColor"
															></path>
															<path
																d="M15 3.5c0 .54-.547.896-.877 1.06A4.878 4.878 0 0 1 12 5a4.878 4.878 0 0 1-2.119-.438C9.551 4.396 9 4.04 9 3.5a.993.993 0 0 1 .409-.75c.143-.124.302-.229.472-.311A4.833 4.833 0 0 1 12 2a4.836 4.836 0 0 1 2.123.439c.17.082.329.187.472.31A.99.99 0 0 1 15 3.5Z"
																fill="currentColor"
															></path>
														</svg>
													</span>
													Scarlab
												</span>
												<span
													className={css({
														marginInline: '8px',
														display: 'flex',
														cursor: 'pointer',
														alignItems: 'center',
														gap: '20px',
														borderRadius: '6px',
														paddingInline: '12px',
														paddingBlock: '8px',
														fontWeight: '500',
														color: 'gray12',
														'&:hover': {
															backgroundColor: 'gray4',
														},
														'@media(min-width:768px)': {
															gap: '12px',
															fontSize: '14px',
															lineHeight: '20px',
														},
													})}
												>
													<span
														className={css({
															flexShrink: '0',
															strokeWidth: '1px',
															color: 'gray11',
															'& > svg': {
																height: '24px',
																width: '24px',
															},
														})}
													>
														<svg
															width="24"
															height="25"
															viewBox="0 0 24 25"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																fillRule="evenodd"
																clipRule="evenodd"
																d="M6.58 3.414a34.063 34.063 0 0 1 10.84 0 5.383 5.383 0 0 1 3.896 3.897 34.067 34.067 0 0 1 0 10.839 5.383 5.383 0 0 1-3.897 3.897 34.06 34.06 0 0 1-10.838 0 5.382 5.382 0 0 1-3.897-3.897 34.064 34.064 0 0 1 0-10.839A5.382 5.382 0 0 1 6.58 3.414Zm6.478 6.449-4.761.553-1.41 5.446c-.108.42-.034.86.206 1.23l3.195-2.98c-.266-.518-.093-1.14.41-1.471a1.332 1.332 0 0 1 1.622.129 1.12 1.12 0 0 1 .138 1.513c-.356.468-1.021.63-1.576.382l-3.196 2.984c.395.227.87.299 1.322.2l5.84-1.315.593-4.441-2.383-2.23Zm1.003-2.868-.914 1.516 3.643 3.646 1.519-.91-4.248-4.252Z"
																fill="currentColor"
															></path>
														</svg>
													</span>
													Tabler
												</span>
											</div>
										</div>
									</div>
								</div>
								<div
									className={css({
										marginTop: 'auto',
									})}
								>
									<div
										className={css({
											paddingBottom: '16px',
										})}
									>
										<div
											className={css({
												display: 'flex',
												flexDirection: 'column',
												gap: '4px',
											})}
										>
											<span
												className={css({
													marginInline: '8px',
													display: 'flex',
													cursor: 'pointer',
													alignItems: 'center',
													gap: '20px',
													borderRadius: '6px',
													paddingInline: '12px',
													paddingBlock: '8px',
													fontWeight: '500',
													color: 'gray12',
													'&:hover': {
														backgroundColor: 'gray4',
													},
													'@media(min-width:768px)': {
														gap: '12px',
														fontSize: '14px',
														lineHeight: '20px',
													},
												})}
											>
												<span
													className={css({
														flexShrink: '0',
														strokeWidth: '1px',
														color: 'gray11',
													})}
												>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														fill="none"
														viewBox="0 0 24 24"
														strokeWidth="1.5"
														stroke="currentColor"
														className={css({
															height: '24px',
															width: '24px',
														})}
													>
														<path
															strokeLinecap="round"
															strokeLinejoin="round"
															d="M16.712 4.33a9.027 9.027 0 011.652 1.306c.51.51.944 1.064 1.306 1.652M16.712 4.33l-3.448 4.138m3.448-4.138a9.014 9.014 0 00-9.424 0M19.67 7.288l-4.138 3.448m4.138-3.448a9.014 9.014 0 010 9.424m-4.138-5.976a3.736 3.736 0 00-.88-1.388 3.737 3.737 0 00-1.388-.88m2.268 2.268a3.765 3.765 0 010 2.528m-2.268-4.796a3.765 3.765 0 00-2.528 0m4.796 4.796c-.181.506-.475.982-.88 1.388a3.736 3.736 0 01-1.388.88m2.268-2.268l4.138 3.448m0 0a9.027 9.027 0 01-1.306 1.652c-.51.51-1.064.944-1.652 1.306m0 0l-3.448-4.138m3.448 4.138a9.014 9.014 0 01-9.424 0m5.976-4.138a3.765 3.765 0 01-2.528 0m0 0a3.736 3.736 0 01-1.388-.88 3.737 3.737 0 01-.88-1.388m2.268 2.268L7.288 19.67m0 0a9.024 9.024 0 01-1.652-1.306 9.027 9.027 0 01-1.306-1.652m0 0l4.138-3.448M4.33 16.712a9.014 9.014 0 010-9.424m4.138 5.976a3.765 3.765 0 010-2.528m0 0c.181-.506.475-.982.88-1.388a3.736 3.736 0 011.388-.88m-2.268 2.268L4.33 7.288m6.406 1.18L7.288 4.33m0 0a9.024 9.024 0 00-1.652 1.306A9.025 9.025 0 004.33 7.288"
														></path>
													</svg>
												</span>
												Need Help?
											</span>
										</div>
									</div>
								</div>
							</div>
							<div
								className={css({
									display: 'flex',
									flexShrink: '0',
									borderTopWidth: '1px',
									borderTopColor: 'gray6',
								})}
							>
								<div
									className={css({
										display: 'block',
										width: '100%',
										flexShrink: '0',
										padding: '16px',
									})}
								>
									<div
										className={css({
											display: 'flex',
											alignItems: 'center',
											paddingInline: '8px',
										})}
									>
										<span
											className={css({
												cursor: 'pointer',
												fontSize: '14px',
												lineHeight: '20px',
												color: 'gray11',
												textDecoration: 'underline',
											})}
										>
											Login
										</span>
										<span
											className={css({
												paddingInline: '12px',
												fontSize: '14px',
												lineHeight: '20px',
												color: 'gray11',
											})}
										>
											or
										</span>
										<span
											className={css({
												cursor: 'pointer',
												fontSize: '14px',
												lineHeight: '20px',
												color: 'gray11',
												textDecoration: 'underline',
											})}
										>
											Sign up
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div
						className={css({
							width: '1014px' as any,
						})}
					>
						<div
							className={css({
								paddingInline: '32px',
								paddingBlock: '32px',
							})}
						>
							<div
								className={css({
									display: 'flex',
									flexDirection: 'column',
									gap: '8px',
								})}
							>
								<h1
									className={css({
										fontSize: '24px',
										fontWeight: '600',
										color: 'gray12',
									})}
								>
									Heroicons
								</h1>
								<div
									className={css({
										display: 'flex',
										gap: '32px',
									})}
								>
									<div
										className={css({
											flexGrow: 1,
										})}
									>
										<div
											className={css({
												display: 'flex',
												flexDirection: 'column',
												gap: '24px',
											})}
										>
											<div
												className={css({
													display: 'flex',
													gap: '12px',
													backgroundColor: 'gray2',
													paddingTop: '24px',
												})}
											>
												<div
													className={css({
														display: 'flex',
														width: '6/12',
													})}
												>
													<div
														className={css({
															pointerEvents: 'none',
															display: 'flex',
															width: '100%',
															alignItems: 'center',
															gap: '12px',
															overflow: 'hidden',
															borderRadius: '8px',
															borderWidth: '1px',
															borderColor: 'gray7',
															backgroundColor: 'gray1',
															paddingBlock: '0px',
															paddingLeft: '12px',
															color: 'gray11',
															'&:hover': {
																borderColor: 'gray8',
															},
															'@media(min-width:640px)': {
																fontSize: '14px',
																lineHeight: '20px',
															},
														})}
													>
														<svg
															xmlns="http://www.w3.org/2000/svg"
															viewBox="0 0 20 20"
															fill="currentColor"
															className={css({
																height: '20px',
																width: '20px',
															})}
														>
															<path
																fillRule="evenodd"
																d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
																clipRule="evenodd"
															/>
														</svg>
														<input
															placeholder="Search icons"
															type="search"
															className={css({
																display: 'block',
																width: '100%',
																borderWidth: '0',
																backgroundColor: 'transparent',
																padding: '0px',
															})}
															defaultValue=""
														/>
													</div>
												</div>
												<div
													className={css({
														width: '3/12',
													})}
												>
													<button
														className={css({
															display: 'flex',
															width: '100%',
															cursor: 'not-allowed',
															alignItems: 'center',
															justifyContent: 'space-between',
															gap: '8px',
															borderRadius: '8px',
															borderWidth: '1px',
															borderColor: 'gray6',
															backgroundColor: 'gray3',
															paddingInline: '16px',
															paddingBlock: '8px',
														})}
													>
														<span
															className={css({
																fontSize: '14px',
																lineHeight: '20px',
															})}
															style={{ pointerEvents: 'none' }}
														>
															All Categories
														</span>
														<span>
															<svg
																xmlns="http://www.w3.org/2000/svg"
																viewBox="0 0 20 20"
																fill="currentColor"
																className={css({
																	height: '20px',
																	width: '20px',
																	flexShrink: 0,
																	color: 'gray11',
																})}
															>
																<path
																	fillRule="evenodd"
																	d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
																	clipRule="evenodd"
																/>
															</svg>
														</span>
													</button>
												</div>
												<div
													className={css({
														width: '3/12',
													})}
												>
													<button
														className={css({
															display: 'inline-flex',
															width: '100%',
															alignItems: 'center',
															justifyContent: 'space-between',
															gap: '8px',
															borderRadius: '8px',
															borderWidth: '1px',
															borderColor: 'gray7',
															backgroundColor: 'gray3',
															paddingInline: '16px',
															paddingBlock: '8px',
															color: 'gray12',
															'&:hover': {
																borderColor: 'gray8',
																backgroundColor: 'gray4',
															},
														})}
													>
														<span
															className={cx(
																truncate(),
																css({
																	fontSize: '14px',
																	lineHeight: '20px',
																}),
															)}
															style={{ pointerEvents: 'none' }}
														>
															Outline
														</span>
														<span>
															<svg
																xmlns="http://www.w3.org/2000/svg"
																viewBox="0 0 20 20"
																fill="currentColor"
																className={css({
																	height: '20px',
																	width: '20px',
																	flexShrink: 0,
																	color: 'gray12',
																})}
															>
																<path
																	fillRule="evenodd"
																	d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
																	clipRule="evenodd"
																/>
															</svg>
														</span>
													</button>
												</div>
											</div>
											<div>Showing 292 icons in 1 category</div>
											<div
												className={css({
													display: 'flex',
													flexDirection: 'column',
													gap: '20px',
												})}
											>
												<div>
													<div
														className={css({
															display: 'flex',
															flexDirection: 'column',
															justifyContent: 'end',
															paddingBottom: '20px',
														})}
														style={{ height: '70px' }}
													>
														<h3>
															<span
																className={css({
																	fontWeight: '700',
																	lineHeight: '1',
																	color: 'gray12',
																})}
															>
																Uncategorized
															</span>{' '}
															<span
																className={css({
																	fontSize: '14px',
																	lineHeight: '20px',
																})}
															>
																292
															</span>
														</h3>
													</div>
													<div
														className={css({
															display: 'grid',
															gridTemplateColumns:
																'repeat(auto-fill,minmax(132px,1fr))' as any,
															gap: '20px',
														})}
													>
														<div>
															<button
																type="button"
																data-state="closed"
																className={css({
																	display: 'flex',
																	height: '150px' as any,
																	width: '100%',
																	flexDirection: 'column',
																	alignItems: 'center',
																	justifyContent: 'center',
																	gap: '12px',
																	borderRadius: '8px',
																	borderWidth: '1px',
																	borderColor: 'gray7',
																	backgroundColor: 'gray1',
																	paddingInline: '16px',
																	'&:focus': {
																		borderColor: 'gray8',
																	},
																})}
															>
																<span
																	className={css({
																		display: 'flex',
																		justifyContent: 'center',
																		'&>svg': {
																			height: '48px',
																			width: '48px',
																		},
																	})}
																	style={{
																		strokeWidth: 'var(--icon-stroke-width)',
																		color: 'var(--icon-color)',
																	}}
																>
																	<svg
																		xmlns="http://www.w3.org/2000/svg"
																		fill="none"
																		viewBox="0 0 24 24"
																		stroke="currentColor"
																	>
																		<path
																			strokeLinecap="round"
																			strokeLinejoin="round"
																			d="M4.26 10.147a60.436 60.436 0 0 0-.491 6.347A48.627 48.627 0 0 1 12 20.904a48.627 48.627 0 0 1 8.232-4.41 60.46 60.46 0 0 0-.491-6.347m-15.482 0a50.57 50.57 0 0 0-2.658-.813A59.905 59.905 0 0 1 12 3.493a59.902 59.902 0 0 1 10.399 5.84 51.39 51.39 0 0 0-2.658.814m-15.482 0A50.697 50.697 0 0 1 12 13.489a50.702 50.702 0 0 1 7.74-3.342M6.75 15a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5zm0 0v-3.675A55.378 55.378 0 0 1 12 8.443m-7.007 11.55A5.981 5.981 0 0 0 6.75 15.75v-1.5"
																		/>
																	</svg>
																</span>
																<h3
																	className={cx(
																		truncate(),
																		css({
																			maxWidth: '100%',
																			overflow: 'hidden',
																			textAlign: 'center',
																			fontSize: '14px',
																			lineHeight: '20px',
																		}),
																	)}
																	title="academic-cap"
																>
																	academic-cap
																</h3>
															</button>
														</div>
														<div>
															<button
																type="button"
																data-state="closed"
																className={css({
																	display: 'flex',
																	height: '150px' as any,
																	width: '100%',
																	flexDirection: 'column',
																	alignItems: 'center',
																	justifyContent: 'center',
																	gap: '12px',
																	borderRadius: '8px',
																	borderWidth: '1px',
																	borderColor: 'gray7',
																	backgroundColor: 'gray1',
																	paddingInline: '16px',
																	'&:focus': {
																		borderColor: 'gray8',
																	},
																})}
															>
																<span
																	className={css({
																		display: 'flex',
																		justifyContent: 'center',
																		'&>svg': {
																			height: '48px',
																			width: '48px',
																		},
																	})}
																	style={{
																		strokeWidth: 'var(--icon-stroke-width)',
																		color: 'var(--icon-color)',
																	}}
																>
																	<svg
																		xmlns="http://www.w3.org/2000/svg"
																		fill="none"
																		viewBox="0 0 24 24"
																		stroke="currentColor"
																	>
																		<path
																			strokeLinecap="round"
																			strokeLinejoin="round"
																			d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75"
																		/>
																	</svg>
																</span>
																<h3
																	className={cx(
																		truncate(),
																		css({
																			maxWidth: '100%',
																			overflow: 'hidden',
																			textAlign: 'center',
																			fontSize: '14px',
																			lineHeight: '20px',
																		}),
																	)}
																	title="adjustments-horizontal"
																>
																	adjustments-horizontal
																</h3>
															</button>
														</div>
														<div>
															<button
																type="button"
																data-state="closed"
																className={css({
																	display: 'flex',
																	height: '150px' as any,
																	width: '100%',
																	flexDirection: 'column',
																	alignItems: 'center',
																	justifyContent: 'center',
																	gap: '12px',
																	borderRadius: '8px',
																	borderWidth: '1px',
																	borderColor: 'gray7',
																	backgroundColor: 'gray1',
																	paddingInline: '16px',
																	'&:focus': {
																		borderColor: 'gray8',
																	},
																})}
															>
																<span
																	className={css({
																		display: 'flex',
																		justifyContent: 'center',
																		'&>svg': {
																			height: '48px',
																			width: '48px',
																		},
																	})}
																	style={{
																		strokeWidth: 'var(--icon-stroke-width)',
																		color: 'var(--icon-color)',
																	}}
																>
																	<svg
																		xmlns="http://www.w3.org/2000/svg"
																		fill="none"
																		viewBox="0 0 24 24"
																		stroke="currentColor"
																	>
																		<path
																			strokeLinecap="round"
																			strokeLinejoin="round"
																			d="M6 13.5V3.75m0 9.75a1.5 1.5 0 0 1 0 3m0-3a1.5 1.5 0 0 0 0 3m0 3.75V16.5m12-3V3.75m0 9.75a1.5 1.5 0 0 1 0 3m0-3a1.5 1.5 0 0 0 0 3m0 3.75V16.5m-6-9V3.75m0 3.75a1.5 1.5 0 0 1 0 3m0-3a1.5 1.5 0 0 0 0 3m0 9.75V10.5"
																		/>
																	</svg>
																</span>
																<h3
																	className={cx(
																		truncate(),
																		css({
																			maxWidth: '100%',
																			overflow: 'hidden',
																			textAlign: 'center',
																			fontSize: '14px',
																			lineHeight: '20px',
																		}),
																	)}
																	title="adjustments-vertical"
																>
																	adjustments-vertical
																</h3>
															</button>
														</div>
														<div>
															<button
																type="button"
																data-state="closed"
																className={css({
																	display: 'flex',
																	height: '150px' as any,
																	width: '100%',
																	flexDirection: 'column',
																	alignItems: 'center',
																	justifyContent: 'center',
																	gap: '12px',
																	borderRadius: '8px',
																	borderWidth: '1px',
																	borderColor: 'gray7',
																	backgroundColor: 'gray1',
																	paddingInline: '16px',
																	'&:focus': {
																		borderColor: 'gray8',
																	},
																})}
															>
																<span
																	className={css({
																		display: 'flex',
																		justifyContent: 'center',
																		'&>svg': {
																			height: '48px',
																			width: '48px',
																		},
																	})}
																	style={{
																		strokeWidth: 'var(--icon-stroke-width)',
																		color: 'var(--icon-color)',
																	}}
																>
																	<svg
																		xmlns="http://www.w3.org/2000/svg"
																		fill="none"
																		viewBox="0 0 24 24"
																		stroke="currentColor"
																	>
																		<path
																			strokeLinecap="round"
																			strokeLinejoin="round"
																			d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z"
																		/>
																	</svg>
																</span>
																<h3
																	className={cx(
																		truncate(),
																		css({
																			maxWidth: '100%',
																			overflow: 'hidden',
																			textAlign: 'center',
																			fontSize: '14px',
																			lineHeight: '20px',
																		}),
																	)}
																	title="archive-box"
																>
																	archive-box
																</h3>
															</button>
														</div>
														<div>
															<button
																type="button"
																data-state="closed"
																className={css({
																	display: 'flex',
																	height: '150px' as any,
																	width: '100%',
																	flexDirection: 'column',
																	alignItems: 'center',
																	justifyContent: 'center',
																	gap: '12px',
																	borderRadius: '8px',
																	borderWidth: '1px',
																	borderColor: 'gray7',
																	backgroundColor: 'gray1',
																	paddingInline: '16px',
																	'&:focus': {
																		borderColor: 'gray8',
																	},
																})}
															>
																<span
																	className={css({
																		display: 'flex',
																		justifyContent: 'center',
																		'&>svg': {
																			height: '48px',
																			width: '48px',
																		},
																	})}
																	style={{
																		strokeWidth: 'var(--icon-stroke-width)',
																		color: 'var(--icon-color)',
																	}}
																>
																	<svg
																		xmlns="http://www.w3.org/2000/svg"
																		fill="none"
																		viewBox="0 0 24 24"
																		stroke="currentColor"
																	>
																		<path
																			strokeLinecap="round"
																			strokeLinejoin="round"
																			d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5m8.25 3v6.75m0 0-3-3m3 3 3-3M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z"
																		/>
																	</svg>
																</span>
																<h3
																	className={cx(
																		truncate(),
																		css({
																			maxWidth: '100%',
																			overflow: 'hidden',
																			textAlign: 'center',
																			fontSize: '14px',
																			lineHeight: '20px',
																		}),
																	)}
																	title="archive-box-arrow-down"
																>
																	archive-box-arrow-down
																</h3>
															</button>
														</div>
														<div>
															<button
																type="button"
																data-state="closed"
																className={css({
																	display: 'flex',
																	height: '150px' as any,
																	width: '100%',
																	flexDirection: 'column',
																	alignItems: 'center',
																	justifyContent: 'center',
																	gap: '12px',
																	borderRadius: '8px',
																	borderWidth: '1px',
																	borderColor: 'gray7',
																	backgroundColor: 'gray1',
																	paddingInline: '16px',
																	'&:focus': {
																		borderColor: 'gray8',
																	},
																})}
															>
																<span
																	className={css({
																		display: 'flex',
																		justifyContent: 'center',
																		'&>svg': {
																			height: '48px',
																			width: '48px',
																		},
																	})}
																	style={{
																		strokeWidth: 'var(--icon-stroke-width)',
																		color: 'var(--icon-color)',
																	}}
																>
																	<svg
																		xmlns="http://www.w3.org/2000/svg"
																		fill="none"
																		viewBox="0 0 24 24"
																		stroke="currentColor"
																	>
																		<path
																			strokeLinecap="round"
																			strokeLinejoin="round"
																			d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5m6 4.125 2.25 2.25m0 0 2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z"
																		/>
																	</svg>
																</span>
																<h3
																	className={cx(
																		truncate(),
																		css({
																			maxWidth: '100%',
																			overflow: 'hidden',
																			textAlign: 'center',
																			fontSize: '14px',
																			lineHeight: '20px',
																		}),
																	)}
																	title="archive-box-x-mark"
																>
																	archive-box-x-mark
																</h3>
															</button>
														</div>
														<div>
															<button
																type="button"
																data-state="closed"
																className={css({
																	display: 'flex',
																	height: '150px' as any,
																	width: '100%',
																	flexDirection: 'column',
																	alignItems: 'center',
																	justifyContent: 'center',
																	gap: '12px',
																	borderRadius: '8px',
																	borderWidth: '1px',
																	borderColor: 'gray7',
																	backgroundColor: 'gray1',
																	paddingInline: '16px',
																	'&:focus': {
																		borderColor: 'gray8',
																	},
																})}
															>
																<span
																	className={css({
																		display: 'flex',
																		justifyContent: 'center',
																		'&>svg': {
																			height: '48px',
																			width: '48px',
																		},
																	})}
																	style={{
																		strokeWidth: 'var(--icon-stroke-width)',
																		color: 'var(--icon-color)',
																	}}
																>
																	<svg
																		xmlns="http://www.w3.org/2000/svg"
																		fill="none"
																		viewBox="0 0 24 24"
																		stroke="currentColor"
																	>
																		<path
																			strokeLinecap="round"
																			strokeLinejoin="round"
																			d="M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3"
																		/>
																	</svg>
																</span>
																<h3
																	className={cx(
																		truncate(),
																		css({
																			maxWidth: '100%',
																			overflow: 'hidden',
																			textAlign: 'center',
																			fontSize: '14px',
																			lineHeight: '20px',
																		}),
																	)}
																	title="arrow-down"
																>
																	arrow-down
																</h3>
															</button>
														</div>
														<div>
															<button
																type="button"
																data-state="closed"
																className={css({
																	display: 'flex',
																	height: '150px' as any,
																	width: '100%',
																	flexDirection: 'column',
																	alignItems: 'center',
																	justifyContent: 'center',
																	gap: '12px',
																	borderRadius: '8px',
																	borderWidth: '1px',
																	borderColor: 'gray7',
																	backgroundColor: 'gray1',
																	paddingInline: '16px',
																	'&:focus': {
																		borderColor: 'gray8',
																	},
																})}
															>
																<span
																	className={css({
																		display: 'flex',
																		justifyContent: 'center',
																		'&>svg': {
																			height: '48px',
																			width: '48px',
																		},
																	})}
																	style={{
																		strokeWidth: 'var(--icon-stroke-width)',
																		color: 'var(--icon-color)',
																	}}
																>
																	<svg
																		xmlns="http://www.w3.org/2000/svg"
																		fill="none"
																		viewBox="0 0 24 24"
																		stroke="currentColor"
																	>
																		<path
																			strokeLinecap="round"
																			strokeLinejoin="round"
																			d="m9 12.75 3 3m0 0 3-3m-3 3v-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0z"
																		/>
																	</svg>
																</span>
																<h3
																	className={cx(
																		truncate(),
																		css({
																			maxWidth: '100%',
																			overflow: 'hidden',
																			textAlign: 'center',
																			fontSize: '14px',
																			lineHeight: '20px',
																		}),
																	)}
																	title="arrow-down-circle"
																>
																	arrow-down-circle
																</h3>
															</button>
														</div>
														<div>
															<button
																type="button"
																data-state="closed"
																className={css({
																	display: 'flex',
																	height: '150px' as any,
																	width: '100%',
																	flexDirection: 'column',
																	alignItems: 'center',
																	justifyContent: 'center',
																	gap: '12px',
																	borderRadius: '8px',
																	borderWidth: '1px',
																	borderColor: 'gray7',
																	backgroundColor: 'gray1',
																	paddingInline: '16px',
																	'&:focus': {
																		borderColor: 'gray8',
																	},
																})}
															>
																<span
																	className={css({
																		display: 'flex',
																		justifyContent: 'center',
																		'&>svg': {
																			height: '48px',
																			width: '48px',
																		},
																	})}
																	style={{
																		strokeWidth: 'var(--icon-stroke-width)',
																		color: 'var(--icon-color)',
																	}}
																>
																	<svg
																		xmlns="http://www.w3.org/2000/svg"
																		fill="none"
																		viewBox="0 0 24 24"
																		stroke="currentColor"
																	>
																		<path
																			strokeLinecap="round"
																			strokeLinejoin="round"
																			d="m19.5 4.5-15 15m0 0h11.25m-11.25 0V8.25"
																		/>
																	</svg>
																</span>
																<h3
																	className={cx(
																		truncate(),
																		css({
																			maxWidth: '100%',
																			overflow: 'hidden',
																			textAlign: 'center',
																			fontSize: '14px',
																			lineHeight: '20px',
																		}),
																	)}
																	title="arrow-down-left"
																>
																	arrow-down-left
																</h3>
															</button>
														</div>
														<div>
															<button
																type="button"
																data-state="closed"
																className={css({
																	display: 'flex',
																	height: '150px' as any,
																	width: '100%',
																	flexDirection: 'column',
																	alignItems: 'center',
																	justifyContent: 'center',
																	gap: '12px',
																	borderRadius: '8px',
																	borderWidth: '1px',
																	borderColor: 'gray7',
																	backgroundColor: 'gray1',
																	paddingInline: '16px',
																	'&:focus': {
																		borderColor: 'gray8',
																	},
																})}
															>
																<span
																	className={css({
																		display: 'flex',
																		justifyContent: 'center',
																		'&>svg': {
																			height: '48px',
																			width: '48px',
																		},
																	})}
																	style={{
																		strokeWidth: 'var(--icon-stroke-width)',
																		color: 'var(--icon-color)',
																	}}
																>
																	<svg
																		xmlns="http://www.w3.org/2000/svg"
																		fill="none"
																		viewBox="0 0 24 24"
																		stroke="currentColor"
																	>
																		<path
																			strokeLinecap="round"
																			strokeLinejoin="round"
																			d="M9 8.25H7.5a2.25 2.25 0 0 0-2.25 2.25v9a2.25 2.25 0 0 0 2.25 2.25h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25H15M9 12l3 3m0 0 3-3m-3 3V2.25"
																		/>
																	</svg>
																</span>
																<h3
																	className={cx(
																		truncate(),
																		css({
																			maxWidth: '100%',
																			overflow: 'hidden',
																			textAlign: 'center',
																			fontSize: '14px',
																			lineHeight: '20px',
																		}),
																	)}
																	title="arrow-down-on-square"
																>
																	arrow-down-on-square
																</h3>
															</button>
														</div>
														<div>
															<button
																type="button"
																data-state="closed"
																className={css({
																	display: 'flex',
																	height: '150px' as any,
																	width: '100%',
																	flexDirection: 'column',
																	alignItems: 'center',
																	justifyContent: 'center',
																	gap: '12px',
																	borderRadius: '8px',
																	borderWidth: '1px',
																	borderColor: 'gray7',
																	backgroundColor: 'gray1',
																	paddingInline: '16px',
																	'&:focus': {
																		borderColor: 'gray8',
																	},
																})}
															>
																<span
																	className={css({
																		display: 'flex',
																		justifyContent: 'center',
																		'&>svg': {
																			height: '48px',
																			width: '48px',
																		},
																	})}
																	style={{
																		strokeWidth: 'var(--icon-stroke-width)',
																		color: 'var(--icon-color)',
																	}}
																>
																	<svg
																		xmlns="http://www.w3.org/2000/svg"
																		fill="none"
																		viewBox="0 0 24 24"
																		stroke="currentColor"
																	>
																		<path
																			strokeLinecap="round"
																			strokeLinejoin="round"
																			d="M7.5 7.5h-.75A2.25 2.25 0 0 0 4.5 9.75v7.5a2.25 2.25 0 0 0 2.25 2.25h7.5a2.25 2.25 0 0 0 2.25-2.25v-7.5a2.25 2.25 0 0 0-2.25-2.25h-.75m-6 3.75 3 3m0 0 3-3m-3 3V1.5m6 9h.75a2.25 2.25 0 0 1 2.25 2.25v7.5a2.25 2.25 0 0 1-2.25 2.25h-7.5a2.25 2.25 0 0 1-2.25-2.25v-.75"
																		/>
																	</svg>
																</span>
																<h3
																	className={cx(
																		truncate(),
																		css({
																			maxWidth: '100%',
																			overflow: 'hidden',
																			textAlign: 'center',
																			fontSize: '14px',
																			lineHeight: '20px',
																		}),
																	)}
																	title="arrow-down-on-square-stack"
																>
																	arrow-down-on-square-stack
																</h3>
															</button>
														</div>
														<div>
															<button
																type="button"
																data-state="closed"
																className={css({
																	display: 'flex',
																	height: '150px' as any,
																	width: '100%',
																	flexDirection: 'column',
																	alignItems: 'center',
																	justifyContent: 'center',
																	gap: '12px',
																	borderRadius: '8px',
																	borderWidth: '1px',
																	borderColor: 'gray7',
																	backgroundColor: 'gray1',
																	paddingInline: '16px',
																	'&:focus': {
																		borderColor: 'gray8',
																	},
																})}
															>
																<span
																	className={css({
																		display: 'flex',
																		justifyContent: 'center',
																		'&>svg': {
																			height: '48px',
																			width: '48px',
																		},
																	})}
																	style={{
																		strokeWidth: 'var(--icon-stroke-width)',
																		color: 'var(--icon-color)',
																	}}
																>
																	<svg
																		xmlns="http://www.w3.org/2000/svg"
																		fill="none"
																		viewBox="0 0 24 24"
																		stroke="currentColor"
																	>
																		<path
																			strokeLinecap="round"
																			strokeLinejoin="round"
																			d="m4.5 4.5 15 15m0 0V8.25m0 11.25H8.25"
																		/>
																	</svg>
																</span>
																<h3
																	className={cx(
																		truncate(),
																		css({
																			maxWidth: '100%',
																			overflow: 'hidden',
																			textAlign: 'center',
																			fontSize: '14px',
																			lineHeight: '20px',
																		}),
																	)}
																	title="arrow-down-right"
																>
																	arrow-down-right
																</h3>
															</button>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div
										className={css({
											width: '256px',
										})}
									>
										<div
											className={css({
												paddingTop: '24px',
											})}
										>
											<div
												className={css({
													borderRadius: '8px',
													borderWidth: '1px',
													borderColor: 'gray6',
													backgroundColor: 'gray1',
												})}
											>
												<div
													className={css({
														padding: '24px',
													})}
												>
													<div
														className={css({
															display: 'flex',
															flexDirection: 'column',
															gap: '20px',
														})}
													>
														<h2
															className={css({
																fontWeight: '700',
																lineHeight: '1',
																color: 'gray12',
															})}
														>
															Customize
														</h2>
														<div
															className={css({
																display: 'flex',
																flexDirection: 'column',
																gap: '16px',
															})}
														>
															<div>
																<div
																	className={css({
																		display: 'flex',
																		flexDirection: 'column',
																		gap: '8px',
																	})}
																>
																	<div
																		className={css({
																			display: 'flex',
																			justifyContent: 'space-between',
																			gap: '8px',
																		})}
																	>
																		<h3
																			className={css({
																				fontSize: '14px',
																			})}
																		>
																			Stroke width
																		</h3>
																		<span
																			className={css({
																				fontSize: '14px',
																			})}
																		>
																			1.5
																		</span>
																	</div>
																	<span>
																		<span
																			className={css({
																				display: 'flex',
																				alignItems: 'center',
																			})}
																		>
																			<span
																				className={css({
																					height: '4px',
																					width: '6/12',
																					borderRadius: '9999px',
																					backgroundColor: 'gray10',
																				})}
																			/>
																			<span
																				className={css({
																					display: 'block',
																					height: '20px',
																					width: '20px',
																					flexShrink: '0',
																					borderRadius: '9999px',
																					backgroundColor: 'gray9',
																					'&:hover': {
																						backgroundColor: 'gray10',
																					},
																				})}
																			/>
																			<span
																				className={css({
																					height: '4px',
																					width: '6/12',
																					borderRadius: '9999px',
																					backgroundColor: 'gray6',
																				})}
																			/>
																		</span>
																	</span>
																</div>
															</div>
															<div>
																<div
																	className={css({
																		display: 'flex',
																		flexDirection: 'column',
																		gap: '8px',
																	})}
																>
																	<h3
																		className={css({
																			fontSize: '14px',
																			lineHeight: '20px',
																		})}
																	>
																		Color
																	</h3>
																	<div
																		className={css({
																			display: 'flex',
																			gap: '8px',
																		})}
																	>
																		<div
																			className={css({
																				display: 'flex',
																			})}
																		>
																			<button
																				className={css({
																					display: 'flex',
																					height: '38px' as any,
																					width: '38px' as any,
																					gap: '8px',
																					borderLeftRadius: '8px',
																					borderWidth: '1px',
																					borderColor: 'gray7',
																					backgroundColor: 'gray3',
																					color: 'gray12',
																					'&:hover': {
																						borderColor: 'gray8',
																						backgroundColor: 'gray4',
																					},
																				})}
																				style={{ backgroundColor: '#674DC3FF' }}
																			/>
																			<div
																				className={css({
																					flex: '1 1 0%',
																				})}
																			>
																				<input
																					type="text"
																					className={css({
																						paddingInline: '12px',
																						paddingBlock: '8px',
																						marginLeft: '-1px',
																						display: 'block',
																						width: '100%',
																						borderRightRadius: '8px',
																						borderWidth: '1px',
																						backgroundColor: 'gray1',
																						color: 'gray11',
																						fontSize: '14px',
																						lineHeight: '20px',
																						borderColor: 'gray7',
																						'&:hover': {
																							borderColor: 'gray8',
																						},
																						'&:focus-visible': {
																							borderColor: 'gray8',
																						},
																					})}
																					defaultValue="#674DC3FF"
																				/>
																			</div>
																		</div>
																		<button
																			type="button"
																			role="switch"
																			aria-checked="false"
																			value="on"
																			aria-label="Toggle dark mode"
																			className={css({
																				display: 'flex',
																				height: '38px' as any,
																				width: '38px' as any,
																				flexShrink: '0',
																				alignItems: 'center',
																				justifyContent: 'center',
																				borderRadius: '8px',
																				borderWidth: '1px',
																				borderColor: 'gray7',
																				backgroundColor: 'gray3',
																				'&:hover': {
																					borderColor: 'gray8',
																					backgroundColor: 'gray4',
																				},
																			})}
																		>
																			<span>
																				<svg
																					xmlns="http://www.w3.org/2000/svg"
																					viewBox="0 0 20 20"
																					fill="currentColor"
																					className={css({
																						height: '20px',
																						width: '20px',
																						color: 'gray11',
																					})}
																				>
																					<path
																						fillRule="evenodd"
																						d="M7.455 2.004a.75.75 0 01.26.77 7 7 0 009.958 7.967.75.75 0 011.067.853A8.5 8.5 0 116.647 1.921a.75.75 0 01.808.083z"
																						clipRule="evenodd"
																					/>
																				</svg>
																			</span>
																		</button>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</foreignObject>
		</svg>
	)
}
